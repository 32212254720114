import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import LandingPage from './pages/LandingPage'
import CurriculumPage from './pages/CurriculumPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import TutionPage from './pages/TutionPage';

const App = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route path="curriculum" element={<CurriculumPage />}/>
        <Route path="contact" element={<ContactPage />}/>
        <Route path="about" element={<AboutPage />}/>
        <Route path="tution" element={<TutionPage />}/>
        <Route path="*" element={<LandingPage />}/>
      </Routes>
      <ScrollToTop />
    </BrowserRouter>
  )
}

export default App