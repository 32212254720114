import React from 'react'
import './reviews.css'
import fivestar from '../../assets/fivestar.png'

export default function Reviews() {
  return (
    <div className="reviews-container">
        <h2 className='reviews-title'>Parents Love Us</h2>
        <div className="reviews-list">
            <div className="review">
                <h3>Meghan B.</h3>
                <p>I have 2 children that have attended this school. One toddler, and one for VPK. As someone who has moved a lot I have had my kids in quite a few different schools and so far this has been the best. The teachers are great, the school is clean, and the administration great. My kids love the school and don't want to leave when I come to pick them up. Great sign!!</p>
                <img src={fivestar} alt="Five stars" />
            </div>
            <div className="review">
                <h3>L. F.</h3>
                <p>AWESOME SCHOOL. I did my research,  interviewed a few schools and this one just felt different. Small and cozy, teachers are fantastic, caring and professional. my son has a 2 week adjustment period where he cried for the first 5 minutes everyday. They were patient and loving with him . Now he loves it and so do I.</p>
                <img src={fivestar} alt="Five stars" />
            </div>
            <div className="review">
                <h3>Ary M.</h3>
                <p>Learning in Motion is a great pre-school with excellent staff members.I have recommended this school to friends of mine that have ended up enrolling their child. My son has been there since he was 3 and he loves going to school.</p>
                <img src={fivestar} alt="Five stars" />
            </div>
        </div>
    </div>
  )
}
