import React from 'react'
import './headercurriculum.css'
import puzzle from '../../assets/puzzle.JPG'

export default function HeaderCurriculum() {
  return (
    <div className='headercurriculum-container'>
        <img alt="Childrens puzzle" src={puzzle}/>
        <h1>Learn About Our Curriculums</h1>
    </div>
  )
}
