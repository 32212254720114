import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Contact from '../components/contact/Contact'

export default function ContactPage() {
  return (
    <>
      <Navbar/>
      <Contact/>
      <Footer/>
    </>
  )
}
