import React from 'react'
import './tution.css'

export default function Tution() {
  return (
    <div className="tution-container">
        <div className='tution-list'>
            <div className='tution'>
                <h3 className='wt'>Wobbler & Toddler</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>3 Days</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>$245</h2>
                        <h2>$210</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>$1060</h2>
                        <h2>$910</h2>
                    </div>
                </div>
            </div>
            <div className='tution'>
                <h3 className='twoyr'>2 Years Old</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>3 Days</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>$230</h2>
                        <h2>$195</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>$995</h2>
                        <h2>$845</h2>
                    </div>
                </div>
            </div>
            <div className='tution'>
                <h3 className='threetofouryr'>3 to 4 Years Old</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>3 Days</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>$220</h2>
                        <h2>$185</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>$950</h2>
                        <h2>$800</h2>
                    </div>
                </div>
            </div>
            <div className='tution'>
                <h3 className='vpk-ext'>VPK</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>VPK Extended Care</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>FREE</h2>
                        <h2>$165</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>FREE</h2>
                        <h2>$715</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='inital'>
            <h3>Initial and Annual Registration Fee - $200</h3>
            <h3>Summer Activity fee - $75</h3>
            <h3>Catered Hot Lunch - $4.50</h3>
            <h3>Pizza Friday - $4</h3>
            <h3>Cot Sheets - $18</h3>
            <h3>Logo T-Shirt - $20 Child, $25 Adult</h3>
            <h3><em>Rates effective July 2024. Rates subject to change. Contact the Director for current rates.</em></h3>
        </div>
    </div>
  )
}
