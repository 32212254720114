import React from 'react'
import './footer.css'
import logo from '../../assets/LIM_STACKEDV1_PRESCHOOL.png'
import NAClogo from '../../assets/NAC Logo.png'
import goalseal from '../../assets/goldseal.png'

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-list">
        <div className="footer">
          <h3>Our Location</h3>
          <h4>10945 Stirling Road, Cooper City, FL 33328</h4>
          <h4>School Hours: 6:30am - 6:30pm</h4>
          <div className='footer-map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.6608329005458!2d-80.29559732401994!3d26.044651196854456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9a6335375abfd%3A0xe86fdd382ed7a0f3!2sLearning%20In%20Motion%20Preschool!5e0!3m2!1sen!2sus!4v1685939795202!5m2!1sen!2sus" 
            title="footer map"
            className="googlemap"
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
        <div className="footer">
          <div className='footer-logos'>
            <img className='footer-logo' src={logo} alt="Learning In Motion Logo"/>
            <img className='nac-logo' src={NAClogo} alt="Asssociation For Early Learning Leaders"/>
            <img className='goalseal-logo' src={goalseal} alt="Florida Quality Care Gold Seal" />
          </div>
        </div>
        <div className="footer">
          <h3>Contact Us</h3>
          <h4 className='footer-number'>954 434-9620</h4>
          <h4>Director, Valentina Penesso Gonzalez</h4>
          <h4>Valentina@LIMPreschool.com</h4>
        </div>
      </div>
      <h4 className="copyright">Learning In Motion Preschool, Inc. ©</h4>
    </div>
  )
}
