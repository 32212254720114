import React from 'react'
import './curriculum.css'
import { Link } from "react-router-dom";
import toddlers from '../../assets/baby_1.jpg'
import twos from '../../assets/girl_3.jpg'
import threes from '../../assets/boy_3.jpg'
import vpk from '../../assets/girl_4.jpg'


export default function Curriculum() {
  return (
    <div className="curriculum-container">
        <h2 className="curriculum-title">Our Curriculums</h2>
        <div className="curriculum-list">
            <div className='curriculum'>
                <img src={toddlers} alt="Toddler" />
                <h3 className='program-title one'>Toddlers</h3>
                <p>Our dynamic environment encourages joyful exploration, creative expression, and independent growth under the guidance of attentive teachers.</p>
            </div>
            <div className='curriculum'>
                <img src={twos} alt="Two Year old" />
                <h3 className='program-title two'>Two's</h3>
                <p>Children engage in imaginative play, develop language skills, socialize, and explore their surroundings, all while preparing for the next stage of potty training.</p>
            </div>
            <div className='curriculum'>
                <img src={threes} alt="Three Year old" />
                <h3 className='program-title three'>Three's</h3>
                <p>Children discover the joy of learning through cooperative play, themed exploration, and emergent literacy in our classroom where friendships flourish and curiosity thrives.</p>
            </div>
            <div className='curriculum'>
                <img src={vpk} alt="Pre K Child" />
                <h3 className='program-title four'>VPK</h3>
                <p>Our well rounded and comprehensive VPK program incorporates the use of several enrichments classes, field trips, and specialty techniques.</p>
            </div>
            {/* <div className='curriculum'>
                <img src={boy_4} alt="" />
                <h3>Summer Camp</h3>
            </div> */}
        </div>
        <Link to='/curriculum' className="viewall-btn">View All Curriculums</Link>
    </div>
  )
}
