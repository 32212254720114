import React from 'react'
import './aboutheader.css'
import girl_13 from '../../assets/girl_13.JPG'

export default function AboutHeader() {
  return (
    <div className="aboutheader-container">
        <img src={girl_13} alt="Girl Smiling"></img>
        <h1>About Our Preschool</h1>
    </div>
  )
}
