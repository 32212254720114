import React from 'react'
import { Link } from "react-router-dom";
import './mission.css'
import penelope_gif from '../../assets/Logo Waving.gif'
import boy_6 from '../../assets/boy_6.JPG'


export default function Mission() {
  return (
    <div className='mission_container'>
      <div className='mission-grid'>
        <div className='mission-item'>
          <img className='mission-img one' src={boy_6} alt="Little boy doing puzzle"></img>
          <img src={penelope_gif} alt="Penelope waving" className="mission-gif" />
        </div>
        <div className='mission-item'>
          <h1 className="mission-title">Our Mission</h1>
          <h2 className="mission-text">Learning In Motion Preschool provides a child-centered, high-energy learning environment that nurtures the intellectual, physical, social, and emotional well-being of children. Through active movement, self-expression, and life skills, we believe in fostering joyful learning experiences that awaken and activate young minds.</h2>
          <Link to='/about' className="learnmore-btn">Learn More</Link>
        </div>
      </div>
    </div>
  )
}
