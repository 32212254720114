import React from 'react'
import Navbar from '../components/navbar/Navbar'
import HeaderCurriculum from '../components/header_curriculum/HeaderCurriculum'
import CurriculumInfo from '../components/header_curriculum/CurriculumInfo'
import Footer from '../components/footer/Footer'
import Programs from '../components/programs/Programs'

export default function CurriculumPage() {
  return (
    <>
      <Navbar/>
      <HeaderCurriculum/>
      <CurriculumInfo/>
      <Programs/>
      <Footer/>
    </>
  )
}
