import React from 'react'
import './contact.css'

export default function Contact() {
  return (
    <div className="contact-container">
      <div className='contact-list'>
        <div className='contact-info'>
        <h2>Contact Us</h2>
        <h3 className='contact-number'>954 434-9620</h3>
        <h3>Director, Valentina Penesso Gonzalez</h3>
        <h3>Valentina@LIMPreschool.com</h3>
        <h2>Our Location</h2>
        <h3>10945 Stirling Road, Cooper City, FL 33328</h3>
        <h3>School Hours: 6:30am - 6:30pm</h3>
        </div>
        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.6608329005458!2d-80.29559732401994!3d26.044651196854456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9a6335375abfd%3A0xe86fdd382ed7a0f3!2sLearning%20In%20Motion%20Preschool!5e0!3m2!1sen!2sus!4v1685939795202!5m2!1sen!2sus" 
          title="contact map"
          className="googlemap"
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
    </div>
  )
}
