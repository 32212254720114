import React from 'react'
import Navbar from '../components/navbar/Navbar'
import TutionHeader from '../components/header_tution/TutionHeader'
import Footer from '../components/footer/Footer'
import Tution from '../components/tution/Tution'

export default function TutionPage() {
  return (
    <>
      <Navbar/>
      <TutionHeader/>
      <Tution/>
      <Footer/>
    </>
  )
}
