import React from 'react'
import './tutionheader.css'
import drawing from '../../assets/drawing.JPG'

export default function TutionHeader() {
  return (
    <div className="tutionheader-container">
        <img src={drawing} alt="Child Drawing"></img>
        <h1>Tuition Rate Information</h1>
    </div>
  )
}
