import React from 'react'
import Header from '../components/header/Header'
import Navbar from '../components/navbar/Navbar'
import Mission from '../components/mission/Mission'
import Curriculum from '../components/curriculum/Curriculum'
import Reviews from '../components/reviews/Reviews'
import Footer from '../components/footer/Footer'

export default function LandingPage() {
  return (
    <>
      <Navbar/>
      <Header/>
      <Mission/>
      <Curriculum/>
      <Reviews/>
      <Footer/>
    </>
  )
}
