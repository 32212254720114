import React from 'react'
import './about.css'
import graphic_1 from '../../assets/graphic_1-removebg.png'

export default function About() {
  return (
    <div className="about-container">
        <div className='about'>
            <h1>Our Mission</h1>
            <h3>We are an early childhood school, owned by a lifelong educator, providing care for children from 1 year old through pre-kindergarten ages.
                We are dedicated to creating a safe, fun and educational environment where young children can learn and grow.
                <br/><br/>
                Learning In Motion is designed to teach children in a fun and creative way, the way children naturally learn, which we believe ensures the best possible 
                development of the child’s intellectual, physical, social, and emotional well being. The program emphasizes active movement, self-expression, and life skills. 
                We offer a child-centered, high energy learning environment that allows thinking and learning to be anchored by movement. This movement awakens and activates 
                the brain and creates richer learning experiences.
            </h3>
            <img src={graphic_1} alt="Drawing of Children"></img>
            <h1>Why Learning In Motion</h1>
            <h3>
            The name of our school, Learning in Motion, and our motto “Learning Anchored by Movement” plays a huge role in our method of teaching. 
            We incorporate motion with learning by creating unique methods to deliver the lessons being taught. For example, while learning colors 
            and letters, an obstacle course may be set up, getting the children active crawling through hoops while naming their colors and jumping 
            on squares while shouting out their letter sounds. This greatly improves learning, but the children really think it’s all fun and games. 
            And, they’re right! We’ve been conditioned to think of learning as work, but we actually learn best during fun and engaging activities.
            We want our children to love learning and we want them to love school!</h3>
        </div>
    </div>
  )
}
