import React from 'react'
import Navbar from '../components/navbar/Navbar'
import AboutHeader from '../components/header_about/AboutHeader'
import About from '../components/about/About'
import Footer from '../components/footer/Footer'

export default function AboutPage() {
  return (
    <>
      <Navbar/>
      <AboutHeader/>
      <About/>
      <Footer/>
    </>
  )
}
