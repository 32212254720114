import React from 'react'
import './curriculuminfo.css'
import frogstreet from '../../assets/frogstreet.png'
import letterppl from '../../assets/letterppl.png'
import logohead from '../../assets/Logo Head.png'

export default function CurriculumInfo() {
  return (
    <div className='curriculuminfo-container'>
        <div className='curriculuminfo-container__title'>
        </div>
        <div className='info-list'>
            <div className='info'>
                <img src={letterppl} alt="Lets Begin with the Letter People Pre-K program"/>
                <h3>The Letter People Curriculum, "Let’s Begin with the Letter People®," is a Pre-K program aligned with the Florida Early Learning and Development Standards. It uses fun characters to engage children in learning and covers different subjects.</h3>
            </div>
            <div className='info'>
                <img className='frogstreet-logo' src={frogstreet} alt="The Frog Street Curriculum"/>
                <h3>The Frog Street Curriculum is a highly regarded early childhood education program that offers engaging activities and age-appropriate materials. It focuses on social-emotional development, language and literacy, math, and science skills.</h3>
            </div>
            <div className='info'>
                <img className='logohead' src={logohead} alt="Learning In Motion Creative Curriculum"/>
                <h2>Learning In Motion Creative Curriculum</h2>
                <h3>At Learning In Motion, our preschool integrates exciting obstacle courses and Learning In Motion Techniques into our curricula. Your child will jump over letter or word hops, practicing reading aloud, and follow the shape of a jump rope formed into letters to reinforce language skills.</h3>
            </div>
        </div>
        <div className='frogstreet-video'>
            <iframe width="660" height="415" src="https://www.youtube.com/embed/ykFgbfiBGOY" title="Frog Street Pre K Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
  )
}
